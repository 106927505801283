<template>
  <div id="login">
    <b-form class="lform">
      <b-container>
        <b-row class="">
          <h1 class="titles">Login</h1>
        </b-row>
        <b-row class="mb-3">
          <p>Acesse sua área de inscrições.</p>
          <p>Para participar dos eventos você deverá estar logado</p>
        </b-row>
        <b-form-group id="input-group-1">
          <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            placeholder="Enter your email:"
            required
            class="mb-3"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2">
          <b-form-input
            id="input-2"
            v-model="form.password"
            placeholder="Enter your password:"
            required
            class="mb-5"
          ></b-form-input>
        </b-form-group>

        <b-button variant="danger" class="w-100 mb-2" @click="login()"
          >Entrar</b-button
        >
        <b-row>
          <b-col md="8">
            <b-form-checkbox
              id="checkbox-1"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
              class="displayBlock"
            >
              Lembre-se de mim
            </b-form-checkbox>
          </b-col>
          <b-col md="4">
            <span>
              <a href="#">
                <strong>Esqueceu a senha? </strong>
              </a>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <p class="mt-5">
            Esta página é protegida pelo Google reCAPTCHA para garantir que você
            não é um robô. Saiba mais.
          </p>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import api from "../axios/axios";
export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["isLogged"]),
    async login() {
      // console.log("ESTOU AKI", form);
      await api
        .post("/login", {
          email: this.form.email,
          password: this.form.password,
        })
        .then((response) => {
          // localStorage.setItem('token', `Bearer ${response.access_token}` );
          // axios.defaults.headers.post["authorization"] = localStorage.token;
          console.log('ola')
          if (response.data.accessToken) {
            var loged = true;
            this.isLogged(loged);
            console.log(response.data);
            localStorage.setItem(
              "token",
              `Bearer ${response.data.accessToken}`
            );
            this.$router.push("/");
          }
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
#login {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f8fb;
  padding: 30px 20px;

  color: #fff;
}
.lform {
  margin-top: 20px;
  color: #000;
  min-height: 650px;
  max-width: 550px;
  padding: 0 20px;
  background-color: #fff;
}

.displayBlock {
  float: left;
}
.titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  margin-bottom: 50px;
}
.titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
</style>